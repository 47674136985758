<!-- Header -->
<div class="header">
  <h4>Notifications {{ newCount() ? ('(' + newCount() + ')') : '' }}</h4>
  <ui-menu-button class="transparent" icon="ui-icon-circle-more" [items]="globalActions"
                  (select)="onGlobalAction($event)"></ui-menu-button>
</div>

@if (itemWithActions().length) {
  <div class="list">
    @for (n of itemWithActions(); track $index) {
      <div class="item" (mouseleave)="closeAllMenus()">
        <!-- Logo -->
        <div class="logo">
          <img [src]="n.img || '/assets/img/package.svg'">
        </div>

        <!-- Text -->
        <a class="body-text" [routerLink]="getUrl(n)" [queryParams]="getQueryParams(n)" [fragment]="getFragment(n)" (click)="markAsRead(n);">
          <div class="desc" [ui-markdown]="n.body"></div>
          <div class="date">{{ n.created | date:'dd MMM, YYYY' }}</div>
        </a>

        <!-- Dot and actions -->
        <div class="actions">
          @if (!n.status) {
            <!--            (mouseleave)="menu?.close()" -->
            <ui-menu-button
              #menu
              [attr.data-id]="n.id"
              class="transparent"
              icon="ui-icon-circle-more"
              [yPosition]="($last && $index !== 0) ? 'top' : 'bottom'"
              [items]="n.actions || []"
              (select)="onItemAction($event, n)"
            ></ui-menu-button>
            <div class="dot"></div>
          }
        </div>
      </div>
    }
  </div>
} @else {
  <div class="no-data">
    <div class="ui-icon-subscribe"></div>
    No notifications yet!
  </div>
}
