import {ChangeDetectorRef, Component, inject, Inject, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformServer, Location} from '@angular/common';
import {ModalService} from './services/modal.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  ResolveFn,
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';
import {PartnersDirectoryService} from './services/partnersDirectory';
import {Meta, Title} from '@angular/platform-browser';
import {DefaultResolver} from './default-resolver';
import {PORTAL_VIEW_COMPANY_ROUTE} from './services/site-tracker.service';
import {AuthService} from './services/authService';
import {BreadcrumbsComponent} from './controls/breadcrumbs/breadcrumbs.component';
import {ModalComponent} from './controls/modal/modal';
import {ExternalHeaderComponent} from '../oex-ui-kit/components/external-header/external-header.component';
import {HeaderComponent, IMenu} from '../oex-ui-kit/components/header/header';
import {MenuService} from './services/menu.service';
import {PortalService} from './portal/services/portal-service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ROUTE_MARKETPLACE} from './services/global-types';
import {IHeaderNotification} from '../oex-ui-kit/components/notification-popup/notification-popup.component';
import {APIService} from './services/api.service';
import {NotificationsService} from '../oex-ui-kit/services/notifications.service';
import {ProgressService} from '../oex-ui-kit/services/progress.service';

@Component({
  standalone: true,
  selector: 'oex-root',
  templateUrl: './app.component.html',
  imports: [
    BreadcrumbsComponent,
    RouterOutlet,
    ModalComponent,
    RouterLink,
    ExternalHeaderComponent,
    HeaderComponent
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('header') header?: HeaderComponent;
  title = '';
  public modals: Array<any> = [];
  // isCookieWarning = false;
  isFooter = true;
  isAbout = false;
  isPreview = false;
  currentYear = new Date().getFullYear();
  isPortal = false;
  isMarketplace = false;
  menuItems: IMenu[];
  mpRoute = ROUTE_MARKETPLACE;

  private subCompanyCreated$ = this.portal.onCompanyCreated.pipe(takeUntilDestroyed());
  private onMenuUpdate$ = this.ms.update.pipe(takeUntilDestroyed());
  private onLogout$ = this.auth.onLogout.pipe(takeUntilDestroyed());
  private previousRoute: string;

  constructor(public modal: ModalService,
              private cdr: ChangeDetectorRef,
              private portal: PortalService,
              private router: Router,
              private api: APIService,
              private ps: ProgressService,
              private location: Location,
              private meta: Meta,
              private ts: Title,
              public auth: AuthService,
              private ms: MenuService,
              private ns: NotificationsService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public pd: PartnersDirectoryService) {
    this.menuItems = this.ms.items;
    DefaultResolver.resetMetaTags(this.meta);
    DefaultResolver.setTitle(this.ts);
    this.isMarketplace = this.location.path().startsWith(`/${ROUTE_MARKETPLACE}`);
    this.subscribe();
    this.previousRoute = this.location.path().split('/')[1];
    // this.subscribeForScrollRestoration();
    this.router.initialNavigation();
  }

  resolveNotifications: ResolveFn<IHeaderNotification[]> = async (route:  ActivatedRouteSnapshot) => {
    const api = inject(APIService);
    return await api.getUserNotifications();
  }

  get siteLogo() {
    const r = this.location.path();
    if (r.startsWith('/' + ROUTE_MARKETPLACE)) {
      return 'assets/img/marketplace-logo.svg';
    }
    const isPdPortal = r.startsWith('/portal') || r.startsWith('/about');

    return this.pd.enabled ?
      (isPdPortal ? '/assets/img/partner-portal-logo.svg' : '/assets/img/pd-logo.svg') :
      '/assets/img/logo.svg'
  }

  hasModalDialog() {
    return this.modals.filter(d => d.modal === true).length !== 0;
  }

  showOneTrustSettings() {
    window['OneTrust'].ToggleInfoDisplay();
  }

  onSearch(term: string) {
    let url = this.isMarketplace ? `/${ROUTE_MARKETPLACE}/solutions` : (this.pd.enabled ? '/solutions' : '/');
    url += `?search=${term}&sort=r&fromMenu=1`;
    void this.router.navigateByUrl(url);
  }

  onLogin() {
    this.auth.login();
  }

  onRegisterClick() {
    this.auth.register();
  }

  /**
   * Scroll window to top. Used on route change
   */
  scrollToTop() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    window.scroll(0, 0);
  }

  private subscribe() {
    this.modal.onDialogsChanged.subscribe((d) => {
      this.modals = d;
      this.cdr.detectChanges();
    });
    this.router.events.subscribe(val => {
      /*if (val instanceof NavigationStart) {
        this.previousRoute = this.router.url.split('/')[1];
      }*/
      if (val instanceof NavigationEnd) {
        const seg = val.url.split('/')[1];
        this.isMarketplace = seg === ROUTE_MARKETPLACE;
        this.isPortal = seg === 'portal' || seg === PORTAL_VIEW_COMPANY_ROUTE;
        this.isAbout = seg === 'about';
        this.isFooter = !this.isPortal && seg !== 'admin' && seg !== 'preview';
        this.isPreview = seg === 'preview';

        this.checkForMenuUpdate();
        this.previousRoute = seg;
      }
    });

    const updateMenu = () => {
      this.menuItems = this.ms.items;
      if (this.header) {
        this.header.items = this.menuItems;
        this.header.refresh();
      }
      this.cdr.detectChanges();
    };

    this.ns.onMarkAsRead.subscribe(ids => {
      void this.markAsRead(ids);
    });

    this.onLogout$.subscribe(() => updateMenu());
    this.onMenuUpdate$.subscribe(() => updateMenu());
    this.subCompanyCreated$.subscribe(() => updateMenu());
  }

  private async markAsRead(ids: number[]) {
    this.ps.show()
    try {
      await this.api.markNotificationsAsRead(ids);
      if (this.auth.user?.notifications) {
        this.auth.user.notifications-= ids.length;
        this.ms.update.emit();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.ps.hide();
    }
  }

  private checkForMenuUpdate() {
    // Update menu when navigating from HIH to PD or from PD to HIH
    if ((this.previousRoute === ROUTE_MARKETPLACE && !this.isMarketplace) ||
      (this.previousRoute !== ROUTE_MARKETPLACE && this.isMarketplace)) {
      this.ms.update.emit();
    }
  }

  /*private subscribeForScrollRestoration() {
    this.router.events
      .pipe(takeUntilDestroyed())
      .pipe(filter((event): event is Scroll => event instanceof Scroll),
        pairwise()
      ).subscribe(events => {
      const prevUrl = events[0].routerEvent.url.split('?')[0];
      const currentUrl = events[1].routerEvent.url.split('?')[0];
      if (prevUrl !== currentUrl) {
        // Scroll top only if previous url is different from current
        // This prevents the scroll on query param update in the same page
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }*/
}
