import {adminSidebarItems, guardAdminPanel} from './services/sidebars/admin-sidebar';
import {PackageDetailsResolver} from './pages/package/packageDetailsData';
import {resolveAdminInquiries} from './admin/admin-inquires/admin-inquires.component';
import {Route} from '@angular/router';
import {resolveAdminReviews} from './admin/admin-reviews/admin-reviews.component';
import {resolveAdminFeatureAlerts} from './admin/admin-feature-alerts/admin-feature-alerts.component';
import {resolveAdminCompanies} from './admin/companies/admin-companies';
import {resolveAdminApps} from './admin/apps/admin-apps';
import {resolveCompanyModel} from './pages/company/my-company';
import {resolveAdminIdeas, resolveAdminIdeaStatuses} from './admin/admin-ideas/admin-ideas.component';
import {resolvePortalPullRequests} from './portal/pull-requests/pull-requests.component';
import {resolveAdminUserManagement} from './admin/user-management/user-management.component';

export const ADMIN_PANEL_ROUTE: Route = {
  path: 'admin',
  loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
  canActivate: [guardAdminPanel],
  resolve: {
    sidebar: adminSidebarItems
  },
  title: 'Admin Panel',
  children: [
    {
      path: '',
      redirectTo: 'featuredapps',
      pathMatch: 'full',
    },
    {
      path: 'reviews',
      title: 'Reviews',
      loadComponent: () => import('./admin/admin-reviews/admin-reviews.component').then(m => m.AdminReviewsComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        model: resolveAdminReviews
      }
    },
    {
      path: 'featuredapps',
      title: 'Featured apps',
      loadComponent: () => import('./admin/featured-apps/featured-apps.screen').then(m => m.FeaturedAppsScreenComponent)
    },
    {
      path: 'benefits/intersystems',
      title: 'InterSystems Software Benefits',
      loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
    },
    {
      path: 'benefits/training',
      title: 'Partner Training Benefits',
      loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
    },
    {
      path: 'benefits/support',
      title: 'InterSystems Support Benefits',
      loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
    },
    {
      path: 'benefits/marketing',
      title: 'Marketing',
      loadComponent: () => import('./admin/digital-services/digital-services').then(m => m.DigitalServicesComponent)
    },
    {
      path: 'companies',
      title: 'Companies',
      loadComponent: () => import('./admin/companies/admin-companies').then(m => m.AdminCompaniesComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        model: resolveAdminCompanies
      }
    },
    {
      path: 'solutions',
      title: 'Solutions',
      loadComponent: () => import('./admin/apps/admin-apps').then(m => m.AdminAppsComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        model: resolveAdminApps
      }
    },
    {
      path: 'releases',
      title: 'Releases',
      loadComponent: () => import('./admin/site-releases/site-releases').then(m => m.SiteReleasesComponent)
    },
    {
      path: 'certificates',
      title: 'Certificates',
      loadComponent: () => import('./admin/certificates/admin-certificates.screen').then(m => m.AdminCertificatesScreenComponent)
    },
    {
      path: 'newsletter',
      title: 'Newsletter',
      loadComponent: () => import('./admin/newsletters/admin-newsletters.screen').then(m => m.AdminNewslettersScreenComponent)
    },
    {
      path: 'marketing-tools',
      title: 'Marketing Tools',
      loadComponent: () => import('./admin/marketing-tools/marketing-tools.screen').then(m => m.AdminMarketingToolsScreenComponent)
    },
    {
      path: 'benefits-reports',
      title: 'Benefits Reports',
      loadComponent: () => import('./admin/benefit-reports/benefit-reports.screen').then(m => m.AdminBenefitReportsScreenComponent)
    },
    {
      path: 'rewards/nominations',
      title: 'Nominations',
      loadComponent: () => import('./admin/nominations/nominations.screen').then(m => m.AdminNominationsScreenComponent)
    },
    {
      path: 'rewards/list',
      title: 'Rewards',
      loadComponent: () => import('./admin/admin-rewards/admin-rewards.screen').then(m => m.AdminRewardsScreenComponent)
    },
    {
      path: 'app-quality',
      title: 'App quality management',
      loadComponent: () => import('./admin/app-quality/admin-app-quality').then(m => m.AdminAppQualityComponent)
    },
    {
      path: 'inquiries',
      title: 'Health Innovation Hub Inquires',
      loadComponent: () => import('./admin/admin-inquires/admin-inquires.component').then(m => m.AdminInquiresComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        data: resolveAdminInquiries
      }
    },
    {
      path: 'company/:nws',
      loadComponent: () => import('./pages/company/my-company').then(m => m.MyCompanyComponent),
      resolve: {
        model: resolveCompanyModel
      },
      runGuardsAndResolvers: 'paramsChange',
      data: {fromRoute: true}
    },
    {
      path: 'package/:id',
      loadComponent: () => import('./pages/package/packageDetails').then(m => m.PackageDetailsScreenComponent),
      resolve: {model: PackageDetailsResolver}
    },
    {
      path: 'feature-alerts',
      title: 'Feature Alerts',
      loadComponent: () => import('./admin/admin-feature-alerts/admin-feature-alerts.component').then(m => m.AdminFeatureAlertComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {model: resolveAdminFeatureAlerts}
    },
    {
      path: 'ideas',
      title: 'Ideas',
      loadComponent: () => import('./admin/admin-ideas/admin-ideas.component').then(m => m.AdminIdeasComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        model: resolveAdminIdeas,
        statuses: resolveAdminIdeaStatuses
      }
    },
    {
      path: 'user-management',
      title: 'User management',
      loadComponent: () => import('./admin/user-management/user-management.component').then(m => m.UserManagementComponent),
      runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      resolve: {
        model: resolveAdminUserManagement
      }
    },
  ]
};
